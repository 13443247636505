import { storeToRefs } from 'pinia'
import useModal from '../useModal'
import { useAppStore } from '~/store/app'
import { MODAL, PROMOTION_PLAN_TYPE } from '~/constants'
import { useLobbyGameApi } from '~/api/lobby-game'
import { MODAL_TYPES } from '~/config/constant'

export default () => {
  const { $pinia, $axios, $alertMsg } = useNuxtApp()
  const { openModalOne, openModalWithNavigate } = useModal()
  const { LIVE_CASINO_URL, E_SPORTS, VIRTUAL_GAME, GAME_URL } = useLobbyGameApi()
  const { setGameItem } = useAppStore()
  const store = useAppStore($pinia)

  const { currentUser } = storeToRefs(store)
  const router = useRouter()
  const route = useRoute()

  const navigationCheckLoggedInAndOpenGame = async (item: Record<string, any>, isOpenGame = false) => {
    if (!currentUser.value) {
      setGameItem(item)
      openModalWithNavigate(MODAL_TYPES.LOGIN, 'login')
      return
    }
    // Block game with the first deposit and second deposit promotions
    const blockGameWithPromotion = [
      PROMOTION_PLAN_TYPE.WELCOME,
      PROMOTION_PLAN_TYPE.WELCOME2,
      PROMOTION_PLAN_TYPE.WELCOME3,
      PROMOTION_PLAN_TYPE.PROMOTION
    ].includes(currentUser.value.package_id)
    if (blockGameWithPromotion && item.deny_info && item.deny_info === 'WELCOME') {
      openModalOne(MODAL.BLOCK_GAME)
      return
    }

    if (currentUser.value && currentUser.value.username === currentUser.value.fullname.toLowerCase()) {
      setGameItem(item)
      openModalOne(MODAL.DISPLAY_NAME)
      return
    }

    if (currentUser.value?.balance === 0 && !isOpenGame) {
      setGameItem(item)
      openModalOne(MODAL.REQUIRE_DEPOSIT)
      return
    }

    setGameItem({})
    if (route.fullPath.includes('live-casino')) {
      openCasino(item)
      return
    } else if (item.link === '/esports') {
      openEsport()
      return
    } else if (item.link === '/vssport') {
      openVirtualSport()
      return
    }
    await openGame(item)
  }

  const openCasino = async (item: Record<string, any>) => {
    const response = await $axios.$get(LIVE_CASINO_URL, {
      params: {
        partnerCode: item.partner ? item.partner : item.partner_provider,
        partnerGameId: item.partner_game_id,
        tableId: item.table_id,
        depositPath: 'account/deposit?type=bank'
      }
    })
    if (response && response.status === 'OK') {
      setTimeout(() => {
        setTimeout(() => {
          window.open(response.data.url, '_blank')
        }, 100)
      }, 200)
    }
  }

  const openGameByUrl = async (url: string) => {
    const queryString = url.includes('?') ? url.split('?')[1] : url
    const searchParams = new URLSearchParams(queryString)
    const item: any = {}
    searchParams.forEach((value, key) => {
      const camelCaseKey = key.replace(/([a-z])([a-z]*)/gi, (_, p1, p2) =>
        p1.toLowerCase() + p2.charAt(0).toUpperCase() + p2.slice(1).toLowerCase()
      )
      item[camelCaseKey] = value
    })
    await navigationCheckLoggedInAndOpenGame(item)
  }

  const openGame = async (item: Record<string, any>) => {
    const response = await $axios.$get(GAME_URL, {
      params: {
        partnerProvider: item?.partner_provider,
        partnerGameId: item?.partner_game_id,
        ru: route.fullPath
      }
    })
    if (response && response.status === 'OK') {
      setTimeout(() => {
        window.open(response.data.url, '_blank')
      }, 200)
    }
  }

  const openEsport = async () => {
    try {
      const result = await $axios.$get(E_SPORTS)
      if (result && result.status === 'OK') {
        if (result.data.maintenance) {
          $alertMsg('fail', 'Hiện tại đang bảo trì. Vui lòng quay lại sau!')
          router.push('/maintain')
        } else {
          setTimeout(() => {
            window.open(result.data.url, '_blank')
          }, 100)
          return
        }
      }
    } catch (error) {
      $alertMsg('error', 'Hệ thống đang gặp vấn đề, vui lòng thử lại hoặc liên hệ hỗ trợ viên để được hỗ trợ.')
      return '/maintain'
    }
  }

  const openVirtualSport = async () => {
    try {
      const result = await $axios.$get(VIRTUAL_GAME)
      if (result && result.status === 'OK') {
        if (result.data.maintenance) {
          $alertMsg('fail', 'Hiện tại đang bảo trì. Vui lòng quay lại sau!')
          router.push('/maintain')
        } else {
          setTimeout(() => {
            window.open(result.data.url, '_blank')
          }, 100)
          return
        }
      }
    } catch (error) {
      $alertMsg('error', 'Hệ thống đang gặp vấn đề, vui lòng thử lại hoặc liên hệ hỗ trợ viên để được hỗ trợ.')
      return '/maintain'
    }
  }
  return {
    openGameByUrl,
    navigationCheckLoggedInAndOpenGame
  }
}
